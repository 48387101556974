import firebase from "firebase/app";

const defaultState = {
  data: [],
  dataTf: [],
  dataDelete: [],
};

const mutations = {
  SET_DATA(state, em) {
    state.data = em;
    console.log("DATA", em);
  },
  SET_DATA_TF(state, em) {
    state.dataTf = em;
    console.log("DATA TF", em);
  },
  SET_DATA_DELETE(state, em) {
    state.dataDelete = em;
    console.log("DATA DELETE", em);
  },
};

const actions = {
  async getData({ commit }) {
    var username = sessionStorage.getItem("usernameSession");
    var database = firebase.database().ref("User");
    await database
      .child(username)
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          commit("SET_DATA", data);
        } else {
          console.log("No data available");
        }
      })
      .catch(function(error) {
        console.error(error);
      });
  },
  async getDataTf({ commit }) {
    var username = sessionStorage.getItem("usernameSession");
    var database = firebase.database().ref("transaksi");
    await database
      .child(username)
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          let items = [];
          snapshot.forEach((childSnapshot) => {
            // var childKey = childSnapshot.key;
            var childData = childSnapshot.val();
            items.push(childData);
          });
          commit("SET_DATA_TF", items);
        } else {
          commit("SET_DATA_TF", []);
          console.log("No data available");
        }
      })
      .catch(function(error) {
        console.error(error);
      });
  },
  async deleteAction({ commit }) {
    var username = sessionStorage.getItem("usernameSession");
    var idEdit = sessionStorage.getItem("idForEdit");
    var database = firebase.database().ref("transaksi");
    await database
      .child(username)
      .child(idEdit)
      .remove()
      .then(() => {
        commit("SET_DATA_DELETE", "delete");
      })
      .catch(function(error) {
        commit("SET_DATA_DELETE", "fail");
        console.error(error);
      });
  },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};

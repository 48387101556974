<template>
  <v-app>
    <VideoConference />
  </v-app>
</template>

<script>
// @ is an alias to /src
import VideoConference from "@/components/VideoConference";

export default {
  name: "Home",
  components: {
    VideoConference,
  },
};
</script>

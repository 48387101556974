import Vue from "vue";
import Vuex from "vuex";
import login from "./login/index";
import home from "./home/index";
import firebase from "../firebase";

Vue.use(Vuex);
Vue.use(firebase);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { login, home },
});

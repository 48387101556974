import firebase from "firebase";

const defaultState = {
  loginState: [],
  loginStatus: "false",
  authenticated: false,
};

const mutations = {
  setLoginData(state, em) {
    state.loginState = em;
  },
  setLoginStatus(state, em) {
    state.loginStatus = em;
  },
  setAuth(state, em) {
    state.authenticated = em;
  },
};

const actions = {
  async loginAction({ commit }, { email, password }) {
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        var user = firebase.auth().currentUser;

        if (user != null) {
          user.providerData.forEach(function(profile) {
            console.log("profile", profile);
            console.log("Sign-in provider: " + profile.providerId);
            console.log("  Provider-specific UID: " + profile.uid);
            console.log("  Name: " + profile.displayName);
            console.log("  Email: " + profile.email);
            console.log("  Photo URL: " + profile.photoURL);
          });
        }
        commit("setLoginStatus", "true");
        alert("Successfully logged in");

        // this.$router.push('/dashboard');
      });
  },

  async logoutAction({ commit }) {
    commit("setLoginStatus", "false");
    sessionStorage.removeItem("loginSession");
    sessionStorage.removeItem("usernameSession");
  },
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};

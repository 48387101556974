<template>
  <v-app>
    <v-dialog
      v-if="dialog === true"
      persistent
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="justify-center mb-4"
          >This Field Must Be Completed</v-card-title
        >
        <v-form v-model="isValid">
          <v-text-field
            class="px-6"
            v-model="name"
            dense
            label="Name"
            outlined
            color="primary"
            :rules="[(v) => !!v || 'Name is required']"
            required
          ></v-text-field>
          <v-text-field
            class="px-6"
            v-model="meeting"
            dense
            label="Meeting Name"
            outlined
            color="primary"
            :rules="[(v) => !!v || 'Meeting Name is required']"
            required
          ></v-text-field>
          <v-text-field
            class="px-6"
            v-model="password"
            dense
            label="Password (Optional)"
            outlined
            color="primary"
          ></v-text-field>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn
              block
              :color="'primary'"
              :disabled="!isValid"
              @click="dialog = false"
              type="submit"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <vue-jitsi-meet
      v-else
      id="jitsiRef"
      class="wrap"
      ref="jitsiRef"
      domain="meet.jit.si"
      :options="jitsiOptions"
    ></vue-jitsi-meet>
  </v-app>
</template>

<script>
import { JitsiMeet } from "@mycure/vue-jitsi-meet";
export default {
  data: () => ({
    isValid: true,
    dialog: true,
    meeting: "",
    name: "",
    password: "",
    participantInfo: {
      displayName: "",
      formattedDisplayName: "",
      id: "",
    },
  }),
  components: {
    VueJitsiMeet: JitsiMeet,
  },
  computed: {
    jitsiOptions() {
      return {
        roomName: this.meeting,
        width: "100%",
        height: "100%",
        noSSL: false,
        userInfo: {
          email: `${this.name}@gmail.com`,
          displayName: this.name,
        },
        configOverwrite: {
          enableNoisyMicDetection: false,
        },
        interfaceConfigOverwrite: {
          SHOW_JITSI_WATERMARK: false,
          SHOW_WATERMARK_FOR_GUESTS: false,
          SHOW_CHROME_EXTENSION_BANNER: false,
        },
        onload: this.onIFrameLoad,
      };
    },
  },
  watch: {
    participantInfo(val) {
      console.log(val);
    },
  },
  methods: {
    onIFrameLoad() {
      // do stuff
      this.$refs.jitsiRef.addEventListener("readyToClose", this.onMeeting);
      this.$refs.jitsiRef.addEventListener(
        "participantRoleChanged",
        this.meetingPassword
      );
      this.$refs.jitsiRef.addEventListener(
        "participantJoined",
        this.getParticipantJoined
      );
      this.$refs.jitsiRef.addEventListener(
        "participantLeft",
        this.getParticipantLeft
      );
      this.$refs.jitsiRef.on("passwordRequired", function() {
        this.$refs.jitsiRef.executeCommand("password", this.password);
      });
      this.$refs.jitsiRef.executeCommand("hangup");
    },
    onMeeting() {
      window.location.reload();
    },
    meetingPassword(event) {
      if (event.role === "moderator") {
        this.$refs.jitsiRef.executeCommand("password", this.password);
      }
    },
    getParticipantJoined(event) {
      this.participantInfo = event;
      console.log("participant", this.participantInfo);
    },
    getParticipantLeft(event) {
      this.participantInfo = event;
      console.log("participant", this.participantInfo);
    },
  },
};
</script>

<style scoped>
.wrap {
  margin-bottom: -10;
}
</style>
